import { currentMessages } from "./translations"
import Notification from "./notifications"
$(() => {
  console.log("customer edit validate")
  $("#customerEdit").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      console.log("ERRORS!")
      console.log(validator.numberOfInvalids())
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    rules: {
      vat_number: {
        required: true,
      },
      postcode: {
        remote: {
          url: `${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
          type: "get",
          data: {
            iso_code: function () {
              return $("#postcode").data().isoCode
            },
            value: function () {
              return $("#postcode").val()
            },
          },
          dataFilter: function (data) {
            var json = JSON.parse(data)
            return json.valid
          },
        },
      },
      // email: {
      //     required: true,
      //     email: true,
      //     remote:{
      //         url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
      //         type:'get',
      //         data:{
      //             value:function(){
      //                 return $('#companyEmail').val()
      //             }
      //         },
      //         dataFilter: function(data) {
      //             const json = JSON.parse(data)
      //             return json.used === false
      //
      //         }
      //     }
      // },
      email: {
        accept: "[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, }" 
      },
      companyPhone: {
        required: true,
      },
    },
    messages: {
      email: {
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail: currentMessages.uniqueEmail,
        remote: currentMessages.uniqueEmail,
      },
      companyPhone: currentMessages.required,
      vat_number: {
        required: currentMessages.required,
        tinValid: currentMessages.validTin,
      },
      companyCityCode: {
        remote: currentMessages.validPostCode,
      },
    },
    submitHandler: function (forms) {
      console.log("SET")
      $this = $(forms)
      var url = $this.data().url
      var customers = []
      var id = $this.data().id
      var id_address = $this.data().addressId
      var form = new FormData()
      const inputs = $(".customerEdit").find("input")
      const selects = $(".customerEdit").find("select")

      inputs.each(function () {
        const name = $(this).attr("name")
        const val = $(this).val()
        form.append(name, val)
      })
      selects.each(function () {
        const name = $(this).attr("name")
        const val = $(this).val()
        // console.log('customer',id,name,val)
        form.append(name, val)
      })

      form.append("id", id)
      form.append("id_address", id_address)

      // for (var pair of form.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1])
      // }
      // const test = []
      customers.forEach((obj) => {
        console.log(obj)
      })
      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          new Notification("success", "error")
        },
      })
    },
  })
  // EDIT CONTACT PPL
  jQuery.validator.addMethod("accept", (value, element, param) => {
    return value.match(new RegExp("^" + param + "$"))
})

  $("#customerContactEdit").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    rules: {
      "name_surname[]": {
        required: true,
      },
     
      companyPhone: {
        required: true,
      },
      'email[0]' : {
        required: true,
        email: true,
        // remote:{
        //     url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
        //     type:'get',
        //     data:{
        //         value:function(){
        //             return $('#companyEmail').val()
        //         }
        //     },
        //     dataFilter: function(data) {
        //         const json = JSON.parse(data)
        //         return json.used === false

        //     },
        // },
        customemail: true

        
    },
      'email[3]' : {
        required: true,
        email: true,
      
        customemail: true

        
    },
    'email[4]': {
        required: true,
        email: true,
        
        customemail: true

        
    },
    'email[5]': {
        required: true,
        email: true,
        remote:{
            url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
            type:'get',
            data:{
                value:function(){
                    return $('#companyEmail').val()
                }
            },
            dataFilter: function(data) {
                const json = JSON.parse(data)
                return json.used === false

            },
        },
        customemail: true

        
    },
    'email[6]': {
        required: true,
        email: true,
        remote:{
            url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
            type:'get',
            data:{
                value:function(){
                    return $('#companyEmail').val()
                }
            },
            dataFilter: function(data) {
                const json = JSON.parse(data)
                return json.used === false

            },
        },
        customemail: true

        
    },
    },
    messages: {
      'email[0]': {
        customemail: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail:currentMessages.uniqueEmail,
        remote:currentMessages.uniqueEmail
    },
      'email[3]': {
        customemail: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail:currentMessages.uniqueEmail,
        remote:currentMessages.uniqueEmail
    },
    'email[4]': {
        customemail: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail:currentMessages.uniqueEmail,
        remote:currentMessages.uniqueEmail
    },
    'email[5]': {
        customemail: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail:currentMessages.uniqueEmail,
        remote:currentMessages.uniqueEmail
    },
    'email[6]': {
        customemail: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail:currentMessages.uniqueEmail,
        remote:currentMessages.uniqueEmail
    },
      email: {
        accept: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail: currentMessages.uniqueEmail,
        remote: currentMessages.uniqueEmail,
      },
      companyPhone: currentMessages.required,
      vat_number: {
        required: currentMessages.required,
        tinValid: currentMessages.validTin,
      },
      companyCityCode: {
        remote: currentMessages.validPostCode,
      },
    },
    submitHandler: function (forms) {
      $this = $(forms)
      var customers = []
      var form = new FormData()
      var url = $this.data().url
      const ids = []
      const lg = $this.data().init
      const selects = $("#customerContactEdit").find("select")
      const inputs = $("#customerContactEdit").find("input")
      selects.each(function () {
        const name = $(this).attr("name")
        const id = $(this).data().id

        const newName = name.replace(/\[[^\]]+\]/g, ``)

        const val = $(this).val()
        // console.log('customer',id,name,val)
        // t[id] = val
        form.append(`data[${id}][${newName}]`, val)
      })
      inputs.each(function () {
        const $this = $(this)
        if ($this.hasClass("input-wrapper-xl__input")) {
          console.log($this)
          const id = $(this).data().id
          const name = $(this).attr("name")
          const newName = name.replace(/\[[^\]]+\]/g, ``)
          const val = $(this).val()
          console.log("customer", id, newName, val)
          form.append(`data[${id}][${newName}]`, val)

          // if(!ids.includes(id)){
          //     if(id <=$this.data().init){
          //         form.append(`data[${id}][id_contact_person]`,id)
          //     }
          //     ids.push(id)
          // }
        }
      })

      for (var pair of form.entries()) {
        console.log(pair[0] + ", " + pair[1])
      }

      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          // debugger
          location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          let closeNot = new Notification("danger", "coś poszło nie tak :(")
          const errorNot = document.querySelector("li.alert.alert-danger")
          setTimeout(() => errorNot.remove(), 4000)
        },
      })
    },

  })

  //    EDIT ALLEGRO

  $("#customerAllegroEdit").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    submitHandler: function (forms) {
      $this = $(forms)
      var url = $this.data().url
      var target = $this.data().target
      // var customers = [ ]
      var form = new FormData()
      // const selects = $(target).find("select")[0]
      // selects.each(function(){
      //     const name = $(this).attr('name')
      //     const val = $(this).val()
      //     // console.log('customer',id,name,val)
      //     form.append(name,val)
      // })

      const inputs = $(target).find("input")
      const ids = []
      inputs.each(function () {
        if ($(this).hasClass("input-wrapper-xl__input")) {
          const id = $(this).data().id
          const name = $(this).attr("name")
          const newName = name.replace(/\[[^\]]+\]/g, ``)
          const val = $(this).val()
          const type = $this.data().type
          console.log($(this))
          form.append(`data[${id}][${newName}]`, val)

          form.append(`data[${id}][type]`, type)

          if (!ids.includes(id)) {
            console.log(type)
            ids.push(id)
          }
        }
      })

      for (var pair of form.entries()) {
        console.log(pair[0] + ", " + pair[1])
      }

      console.log($this.data().init)
      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          // location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          new Notification("danger", "coś poszło nie tak :(")
        },
      })
    },
  })

  // EDIT OTHER

  $("#customerOtherEdit").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    submitHandler: function (forms) {
      $this = $(forms)
      var url = $this.data().url
      var target = $this.data().target
      // var customers = [ ]
      var form = new FormData()

      const inputs = $(target).find("input")
      const ids = []
      inputs.each(function () {
        if ($(this).hasClass("input-wrapper-xl__input")) {
          const id = $(this).data().id
          const name = $(this).attr("name")
          const newName = name.replace(/\[[^\]]+\]/g, ``)
          const val = $(this).val()
          const type = $this.data().type
          console.log($(this))
          form.append(`data[${id}][${newName}]`, val)

          form.append(`data[${id}][type]`, type)

          if (!ids.includes(id)) {
            console.log(type)
            ids.push(id)
          }
        }
      })

      for (var pair of form.entries()) {
        console.log(pair[0] + ", " + pair[1])
      }

      console.log($this.data().init)
      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          // debugger
          location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          new Notification("danger", "coś poszło nie tak :(")
        },
      })
    },
  })

  //     EDIT EMAIL

  $("#customerEmail").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      console.log("ERRORS!")
      console.log(validator.numberOfInvalids())
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    rules: {
      email: {
        required: true,
        email: true,
        remote: {
          url: `${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
          type: "get",
          data: {
            value: function () {
              return $("#companyEmail").val()
            },
          },
          dataFilter: function (data) {
            const json = JSON.parse(data)
            return json.used === false
          },
        },
      },
    },
    messages: {
      email: {
        email: currentMessages.validMail,
        emailCheck: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail: currentMessages.uniqueEmail,
        remote: currentMessages.uniqueEmail,
      },
    },
    submitHandler: function (forms) {
      console.log("SET")
      $this = $(forms)
      var url = $this.data().url
      // var customers = [ ]
      var id = $this.data().id
      const id_address = $this.data().addressId
      var form = new FormData()
      const inputs = $this.find("input")
      inputs.each(function () {
        const name = $(this).attr("name")
        const val = $(this).val()
        form.append(name, val)
      })

      form.append("id", id)
      form.append("id_address", id_address)

      // customers.forEach((obj)=>{
      //     console.log(obj)
      // })
      console.log("zmiana adresu")
      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          new Notification("success", "error")
        },
      })
    },
  })

  //    EDIT STATIONARY SHOP

  $("#stationaryShopEdit").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      console.log("ERRORS!")
      console.log(validator.numberOfInvalids())
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    rules: {
      postcode: {
        remote: {
          url: `${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
          type: "get",
          data: {
            iso_code: function () {
              return $(this).data().isoCode
            },
            value: function () {
              return $(this).val()
            },
          },
          dataFilter: function (data) {
            var json = JSON.parse(data)
            return json.valid
          },
        },
      },
      // email: {
      //     required: true,
      //     email: true,
      //     remote:{
      //         url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
      //         type:'get',
      //         data:{
      //             value:function(){
      //                 return $('#companyEmail').val()
      //             }
      //         },
      //         dataFilter: function(data) {
      //             const json = JSON.parse(data)
      //             return json.used === false
      //
      //         }
      //     }
      // },
      companyPhone: {
        required: true,
      },
      email: {
        accept: "[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, }" 
      },
    },
    messages: {
      email: {
        accept: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail: currentMessages.uniqueEmail,
        remote: currentMessages.uniqueEmail,
      },
      companyPhone: currentMessages.required,
      vat_number: {
        required: currentMessages.required,
        tinValid: currentMessages.validTin,
      },
      companyCityCode: {
        remote: currentMessages.validPostCode,
      },
    },
    submitHandler: function (forms) {
      $this = $(forms)
      var url = $this.data().url
      var target = $this.data().target
      // var customers = [ ]
      var form = new FormData()
      const selects = $(target).find("select").val()
      // form.append('country_id',selects)
      // debugger
      const t = []
      const selects2 = $(target).find("select")
      selects2.each(function () {
        const name = $(this).attr("name")
        const id = $(this).data().id

        const newName = name.replace(/\[[^\]]+\]/g, ``)

        const val = $(this).val()
        // console.log('customer',id,name,val)
        t[id] = val
        form.append(newName, val)
      })
      console.log(selects2)
      const inputs = $(target).find("input")
      const ids = []
      inputs.each(function (_, idx) {
        if ($(this).hasClass("input-wrapper-xl__input")) {
          const id = $(this).data().id
          const name = $(this).attr("name")
          const newName = name.replace(/\[[^\]]+\]/g, ``)
          const val = $(this).val()
          const type = $this.data().type
          console.log($(this))
          form.append(`data[${id}][${newName}]`, val)

          if (!ids.includes(id)) {
            form.append(`data[${id}][type]`, type)

            if (type !== "allegro_shop_type" && type !== "other_shop_type") {
              form.append(`data[${id}][country]`, t[id])
            }
            console.log(type)
            ids.push(id)
          }
        }
      })

      for (var pair of form.entries()) {
        console.log(pair[0] + ", " + pair[1])
      }

      console.log($this.data().init)
      // debugger
      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          new Notification("danger", "coś poszło nie tak :(")
        },
      })
    },
  })

  // EDIT ONLINE SHOP

  $("#onlineShopEdit").validate({
    errorPlacement: function (label, element) {
      label.addClass("grid-error")
      label.insertAfter(element)
    },
    highlight: function (element) {
      console.log(element)
      if ($(element).hasClass("phone-number-fv")) {
        $(element).next().addClass("grid-error")
        $(element).parent().parent().parent().addClass("field-error")
      } else {
        $(element).parent().parent().addClass("field-error")
      }
    },
    unhighlight: function (element) {
      if ($(element).hasClass("phone-number-fv")) {
        $(element).parent().parent().parent().removeClass("field-error")
      } else {
        $(element).parent().parent().removeClass("field-error")
      }
    },
    debug: false,
    errorClass: "invalid",
    errorElement: "div",
    focusInvalid: false,
    focusCleanup: true,
    invalidHandler: function (form, validator) {
      console.log("ERRORS!")
      console.log(validator.numberOfInvalids())
      for (let i in validator.errorMap) {
        console.log(i, ":", validator.errorMap[i])
      }
      if (!validator.numberOfInvalids()) return

      $("html, body").animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 320,
        },
        1000
      )
    },
    wrapper: "div",
    errorLabelContainer: "#messageBox",
    rules: {
      postcode: {
        remote: {
          url: `${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
          type: "get",
          data: {
            iso_code: function () {
              return $(this).data().isoCode
            },
            value: function () {
              return $(this).val()
            },
          },
          dataFilter: function (data) {
            var json = JSON.parse(data)
            return json.valid
          },
        },
      },
      // email: {
      //     required: true,
      //     email: true,
      //     remote:{
      //         url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
      //         type:'get',
      //         data:{
      //             value:function(){
      //                 return $('#companyEmail').val()
      //             }
      //         },
      //         dataFilter: function(data) {
      //             const json = JSON.parse(data)
      //             return json.used === false
      //
      //         }
      //     }
      // },
      companyPhone: {
        required: true,
      },
      email: {
        accept: "[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, }" 
      },
    },
    messages: {
      email: {
        accept: currentMessages.validMail,
        email: currentMessages.validMail,
        required: currentMessages.validMail,
        uniqueEmail: currentMessages.uniqueEmail,
        remote: currentMessages.uniqueEmail,
      },
      companyPhone: currentMessages.required,
      vat_number: {
        required: currentMessages.required,
        tinValid: currentMessages.validTin,
      },
      companyCityCode: {
        remote: currentMessages.validPostCode,
      },
    },
    submitHandler: function (forms) {
      $this = $(forms)
      var url = $this.data().url
      var target = $this.data().target
      // var customers = [ ]
      var form = new FormData()
      const selects = $(target).find("select").val()
      // form.append('country_id',selects)
      // debugger
      const t = []
      const selects2 = $(target).find("select")
      selects2.each(function () {
        const name = $(this).attr("name")
        const id = $(this).data().id

        const newName = name.replace(/\[[^\]]+\]/g, ``)

        const val = $(this).val()
        // console.log('customer',id,name,val)
        t[id] = val
        form.append(newName, val)
      })
      // console.log(selects2)
      const inputs = $(target).find("input")
      const ids = []
      inputs.each(function (_, idx) {
        if ($(this).hasClass("input-wrapper-xl__input")) {
          const id = $(this).data().id
          const name = $(this).attr("name")
          const newName = name.replace(/\[[^\]]+\]/g, ``)
          const val = $(this).val()
          const type = $this.data().type
          // console.log($(this))
          form.append(`data[${id}][${newName}]`, val)

          if (!ids.includes(id)) {
            form.append(`data[${id}][type]`, type)

            if (type !== "allegro_shop_type" && type !== "other_shop_type") {
              form.append(`data[${id}][country]`, t[id])
            }
            // console.log(type)
            ids.push(id)
          }
        }
      })

      for (var pair of form.entries()) {
        console.log(pair[0] + ", " + pair[1])
      }

      // console.log($this.data().init)
      // debugger
      $.ajax({
        type: "POST",
        url,
        data: form,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        success: function (res) {
          location.reload()
          if (prestashop.language.iso_code === "pl") {
            new Notification("success", "pomyślnie zmieniono dane")
          } else {
            new Notification("success", "Change data successed")
          }
        },
        error: function () {
          new Notification("danger", "coś poszło nie tak :(")
        },
      })
    },
  })

  $.validator.messages.required = currentMessages.required
  $.validator.messages.email = currentMessages.validMail
})
