
export class FiltersManager {
    constructor() {
        this.filters = $(".custom-filter")
        console.log("fm")
        this.init()
        this.match = ""
        this.isChange = false
        this.filtersArray = []
        this.resetRootUrl()
        this.selects = []
        this.onlyNew = false
    }

    getParams(param, url) {
        var urlLink = new URL(url)
        var c = urlLink.searchParams.get(param)
        return c
    }

    resetRootUrl() {

        const filters = ['id_category','controller','id_lang']
        this.url = prestashop.urls.current_url.split("?")[0]
        this.url  +='?'
        const urlParams = new URLSearchParams( prestashop.urls.current_url.split("?")[1])
        const
            keys = urlParams.keys(),
            values = urlParams.values(),
            entries = urlParams.entries()

        for(const entry of entries) {

            if(filters.includes(entry[0]))
                this.url +=`${entry[0]}=${entry[1]}&`

        }
        

    }
    setChangeForSelect() {
        this.createParam(this)
    }
    setBlur(that) {
        this.filters.each(function (idx) {
            // if($(this).data().filter =='m'){
            //    return
            // }
            $(this).change(() => {
                const f = this
                that.createParam(f)
            })
        })
    }
    checkOtherFilter(that) {
        this.filters.each(function (idx) {
            that.checkFiltersArray(
                $(this).data().filter,
                $(this).val(),
                $(this).data().option
            )
        })
    }
    createParam(trigger) {
        this.isChange = false
        const $this = $(trigger)
        const val = $this.val()

        this.checkOtherFilter(this)

        const filter = $this.data().filter
        const option = $this.data().option
        if(option==='check'){
            if(this.onlyNew != $this.is(':checked')){
                this.onlyNew = $this.is(':checked')
                this.isChange = true
            }
        }
        console.log(filter,option)
        let inArray = false

        this.getUrl()
    }
    checkFiltersArray(filter, val, option) {
        let inArray = false
        this.filtersArray.forEach((item) => {
            if (item.filter === filter) {
                item.val = val
                inArray = true
                this.isChange = true
                return
            }
        })
        if (!inArray) {
            this.filtersArray.push({ filter, val, option })
        }
    }
    init() {
        this.setBlur(this)
    }
    getUrl() {
        this.resetRootUrl()
        this.match = ""
        const query = []
        console.log(this.filtersArray)
        this.filtersArray.forEach((e) => {
            switch (e.option) {
                case "check":{
                 if(this.onlyNew){
                     query.push('n:1')

                 }
                    break
                }
                case "one": {
                    this.match += e.filter + "=" + e.val + "&"
                    break
                }
                case "array": {
                    if (e.val.length > 0) {
                        query.push(e.filter + ":" + e.val.join(","))
                    }
                    break
                }

            }
        })

        if (query.join("|").length > 1) {

            if (this.getParams("q", prestashop.urls.current_url)) {
            if(this.onlyNew){

            }

                this.match = "q=" + query.join("|") + "&" + this.match
            } else {
                this.match = "q=" + query.join("|") + "&" + this.match
            }
        }
        if (this.getParams("s", prestashop.urls.current_url)) {
            this.match =
                this.match + "s=" + this.getParams("s", prestashop.urls.current_url)
        } else {
            this.match = this.match.slice(0, -1)
        }
        console.log(this.url)
        console.log(this.match)
            window.location = `${this.url} ${this.match}`

    }
}
