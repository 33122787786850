function customQuantity() {
    jQuery(
        '<div class="pt_QuantityNav"><div class="pt_QuantityButton pt_QuantityUp"><span class="icon__mini-arrow-top">+</span></div><div class="pt_QuantityButton pt_QuantityDown"><span class="icon__mini-arrow-bottom">-</span></div></div>'
    ).insertAfter(".pt_Quantity-input")
    jQuery(".pt_Quantity").each(function() {
        console.log('pt_init')
        var spinner = jQuery(this),
            input = spinner.find('input'),
            btnUp = spinner.find(".pt_QuantityUp"),
            btnDown = spinner.find(".pt_QuantityDown"),
            min = input.attr("min"),
            max = input.attr("max"),
            currentValue = parseInt(input.data('number')),
        valOfAmout = input.val(),
            newVal = 0


        spinner.find('input').on('change',(e) => {
            console.log(parseInt(e.target.value))
            if(parseInt(e.target.value) <=max){
                input.data('number',parseInt(e.target.value))
            }else{
                input.data('number',parseInt(max))

            }

        })

        spinner.find('input').blur(function(){
            console.log('down')
            $(this).attr('type','text')

            spinner.find("input").val(input.data('number') + ' szt.')
            spinner.find("input").val(`${input.data('number')} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)
        })


        spinner.find('input').focus(function(){
            $(this).attr('type','number')

            spinner.find("input").val(input.data('number'))
        })

        btnUp.on("click", () => {
            var oldValue = parseFloat(input.val())

            if (oldValue >= max) {
                var newVal = oldValue
            } else {
                var newVal = oldValue + 1
            }
            spinner.find("input").data('number', newVal)
            // spinner.find("input").val(newVal +' 1szt.')
            spinner.find("input").val(`${newVal} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)

            spinner.find("input").trigger("change")
        })
        btnDown.on("click", () => {
            console.log('down')
            var oldValue = parseFloat(input.data('number'))
            if (oldValue <= min) {
                var newVal = oldValue
            } else {
                var newVal = oldValue - 1
            }
            spinner.find("input").val(`${newVal} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)
            spinner.find("input").data('number',newVal)
            spinner.find("input").trigger("change")
        })

        
    })
}



export default  customQuantity
