import Notification from "./notifications"
import { currentMessages } from "./translations"

let files

$("body").on("change", ".setIsoCode", function (e) {
  console.dir(e)
  const prefix = $(this).children("option:selected").data().prefix
  const iso = $(this).children("option:selected").val()

  console.log(prefix)
  const target = $(e.target)
    .parent()
    .parent()
    .parent()
    .parent()
    .find(".postcode")
  console.log(target)
  const t = $('select[name="companyPhonePrefix"]')
  $(target).data("iso-code", iso)
  $(target).val("")

  console.log(target)
  // console.log($(t))
  // console.log(t)
  // setPrefixPhone('.phone-number-fv',this)

  // const pc=$('input[name="postcode"]')
  // console.log(pc.data())
  // $(pc).data('iso-code',iso)
  // $(pc).val('')
})
$(".phonePrefixFvData").on("change", function (e) {
  const prefix = $(this).children("option:selected").data().prefix
  const iso = $(this).children("option:selected").val()

  console.log(prefix)

  const t = $('select[name="companyPhonePrefix"]')
  $(t).val(prefix)

  // console.log($(t))
  // console.log(t)
  // setPrefixPhone('.phone-number-fv',this)

  const pc = $('input[name="postcode"]')
  $(pc).data("iso-code", iso)
  $(pc).val("")
})

$(".js-edit-fields").on("click", function (e) {
  $this = $(this)
  const target = $this.data().field

  const enabled = $this.data().enabled
  const previewD = $(`#${target}`).find('[data-view="preview"]')
  const editD = $(`#${target}`).find('[data-view="edit"]')

  if (enabled) {
    previewD.show()
    editD.hide()
    $this.data("enabled", false)
  } else {
    previewD.hide()
    editD.show()
    $this.data("enabled", true)
  }
})
$('[data-link-action="update-customer-update"]').on("click", function (e) {
  e.preventDefault()
  $this = $(this)
  var url = $this.data().url
  var customers = []
  var id = $(this).data().id
  var id_address = $this.data().addressId
  var form = new FormData()
  const textareas = $(".customerEdit").find("textarea")
  textareas.each(function () {
    const name = $(this).attr("name")
    const val = $(this).val()
    form.append(name, val)
  })
  form.append("id", id)
  form.append("id_address", id_address)

  for (var pair of form.entries()) {
    console.log(pair[0] + ", " + pair[1])
  }
  const test = []
  customers.forEach((obj) => {
    console.log(obj)
  })
  $.ajax({
    type: "POST",
    url,
    data: form,
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    success: function (res) {
      location.reload()
      if (prestashop.language.iso_code === "pl") {
        new Notification("success", "pomyślnie zmieniono dane")
      } else {
        new Notification("success", "Change data successed")
      }
    },
    error: function () {
      new Notification("danger", "error")
    },
  })
})

$('[data-link-action="update-customer"]').on("click", function (e) {
  e.preventDefault()

  $this = $(this)
  var url = $this.data().url
  var customers = []
  var id = $(this).data().id
  var id_address = $this.data().addressId
  var form = new FormData()
  const inputs = $(".customerEdit").find("input")
  const selects = $(".customerEdit").find("select")
  const textareas = $(".customerEdit").find("textarea")

  console.log(selects)
  console.log($("#customerContactEdit").serialize())

  // const valid = $('#customerEdit').validate(
  //     {
  //         errorPlacement: function(label, element) {
  //             label.addClass('grid-error')
  //             label.insertAfter(element)
  //         },
  //         highlight: function(element) {
  //             console.log(element)
  //             if($(element).hasClass('phone-number-fv')){
  //                 $(element).next().addClass('grid-error')
  //                 $(element).parent().parent().parent().addClass("field-error")
  //             }else{
  //                 $(element).parent().parent().addClass("field-error")
  //
  //             }
  //         },
  //         unhighlight: function(element) {
  //             if($(element).hasClass('phone-number-fv')){
  //                 $(element).parent().parent().parent().removeClass("field-error")
  //             }else{
  //                 $(element).parent().parent().removeClass("field-error")
  //
  //             }
  //         },
  //         debug:true,
  //         errorClass: "invalid",
  //         errorElement: "div",
  //         focusInvalid: false,
  //         focusCleanup: true,
  //         invalidHandler: function(form, validator) {
  //             console.log('ERRORS!')
  //             console.log(validator.numberOfInvalids())
  //             for (let i in validator.errorMap) {
  //                 console.log(i, ":", validator.errorMap[i])
  //             }
  //             if (!validator.numberOfInvalids())
  //                 return
  //
  //             $('html, body').animate({
  //                 scrollTop: $(validator.errorList[0].element).offset().top -320
  //             }, 1000)
  //         },
  //         wrapper: "div",
  //         errorLabelContainer: "#messageBox",
  //         rules: {
  //
  //             vat_number:{
  //                 required:true,
  //             },
  //             postcode:{
  //                 remote:{
  //                     url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
  //                     type:'get',
  //                     data:{
  //                         iso_code:function(){
  //                             return $('#companyCityCode').data().isoCode
  //                         },
  //                         value:function(){
  //                             return $('#companyCityCode').val()
  //                         }
  //                     },
  //                     dataFilter: function(data) {
  //                         var json = JSON.parse(data)
  //                         return json.valid
  //
  //                     }
  //                 }
  //             },
  //             'stationaryPostCode[]':{
  //                 remote:{
  //                     url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
  //                     type:'get',
  //                     data:{
  //                         iso_code:function(){
  //                             return $('#companyCityCode').data().isoCode
  //                         },
  //                         value:function(){
  //                             return $('#companyCityCode').val()
  //                         }
  //                     },
  //                     dataFilter: function(data) {
  //                         var json = JSON.parse(data)
  //                         return json.valid
  //
  //                     }
  //                 }
  //             },
  //             email: {
  //                 required: true,
  //                 email: true,
  //                 remote:{
  //                     url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
  //                     type:'get',
  //                     data:{
  //                         value:function(){
  //                             return $('#companyEmail').val()
  //                         }
  //                     },
  //                     dataFilter: function(data) {
  //                         const json = JSON.parse(data)
  //                         return json.used === false
  //
  //                     }
  //                 }
  //             },
  //             companyPhone:{
  //                 required:true,
  //             },
  //             password: {
  //                 required: true,
  //                 minlength: 5
  //             }
  //         },
  //         messages: {
  //             email:{
  //                 email:currentMessages.validMail,
  //                 required: currentMessages.validMail,
  //                 uniqueEmail:currentMessages.uniqueEmail,
  //                 remote:currentMessages.uniqueEmail
  //             },
  //             companyPhone:currentMessages.validPhone,
  //             vat_number:{
  //                 required:currentMessages.required,
  //                 tinValid:currentMessages.validTin
  //             },
  //             companyCityCode:{
  //                 remote:currentMessages.validPostCode
  //             }
  //         },
  //         // submitHandler: function(form) {
  //         //     // e.preventDefault()
  //         //
  //     textareas.each(function(){
  //         const name = $(this).attr('name')
  //         const val = $(this).val()
  //         // console.log('customer',id,name,val)
  //         form.append(name,val)
  //     })
  //     inputs.each(function(){
  //         const name = $(this).attr('name')
  //         const val = $(this).val()
  //         // console.log('customer',id,name,val)
  //         form.append(name,val)
  //     })
  //     selects.each(function(){
  //         const name = $(this).attr('name')
  //         const val = $(this).val()
  //         // console.log('customer',id,name,val)
  //         form.append(name,val)
  //     })
  //
  //     form.append('id',id)
  //     form.append('id_address',id_address)
  //
  //
  //     for (var pair of form.entries()) {
  //         console.log(pair[0]+ ', ' + pair[1])
  //     }
  //     const test = []
  //     customers.forEach((obj)=>{
  //         console.log(obj)
  //     })
  //     $.ajax({
  //         type: "POST",
  //         url,
  //         data: form,
  //         processData: false,
  //         "mimeType": "multipart/form-data",
  //         "contentType": false,
  //         success: function(res){
  //             location.reload()
  //             if(prestashop.language.iso_code==="pl"){
  //                 new Notification('success', 'pomyślnie zmieniono dane')
  //             }
  //             else{
  //                 new Notification('success', 'Change data successed')
  //
  //             }
  //         },
  //         error:function(){
  //             new Notification('success', 'error')
  //         }
  //     })
  //
  // }
  //     })

  // console.log(valid)
})

//upadte password
$('[data-link-action="update-customer-password"]').on("click", function (e) {
  $(".error-password").empty()
  e.preventDefault()
  $this = $(this)
  var url = $this.data().url
  var form = new FormData()
  const oldPass = $(".pass1")
  const newPass = $(".pass2")
  const newPass2 = $(".pass3")

  if (newPass.val() !== newPass2.val()) {
    if (prestashop.language.iso_code === "pl") {
      $(".error-password").html("Podane hasła nie są takie same")
    } else {
      $(".error-password").html(
        "The password and its confirmation do not match"
      )
    }
    return
  }
  form.append("old_password", oldPass.val())
  form.append("new_password", newPass.val())
  $.ajax({
    type: "POST",
    url,
    data: form,
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    success: function (res) {
      location.reload()
      if (prestashop.language.iso_code === "pl") {
        new Notification("success", "pomyślnie zmieniono dane")
      } else {
        new Notification("success", "Change data successed")
      }
    },
    error: function (res) {
      if (res.status === 403) {
        if (prestashop.language.iso_code === "pl") {
          $(".error-password").html("Aktualne hasło jest nieprawidłowe")
        } else {
          $(".error-password").html("Current password is wrong")
        }
      }
      if (res.status === 422) {
        if (prestashop.language.iso_code === "pl") {
          $(".error-password").html(
            "Podane hasło musi zawierać przynamniej 5 znaków"
          )
        } else {
          $(".error-password").html(
            "The password  must contain at least 5 characters"
          )
        }
      }
    },
  })
})

//update  contact persons
$('[data-link-action="update-customer-contact"]').on("click", function (e) {
  e.preventDefault()
  $this = $(this)
  var customers = []
  var form = new FormData()
  var url = $this.data().url
  const ids = []
  const lg = $this.data().init
  const inputs = $("#customerContactEdit").find("input")
  inputs.each(function () {
    const $this = $(this)
    const id = $(this).data().id
    const name = $(this).attr("name")
    const val = $(this).val()
    console.log("customer", id, name, val)
    form.append(`data[${id}][${name}]`, val)

    // if(!ids.includes(id)){
    //     if(id <=$this.data().init){
    //         form.append(`data[${id}][id_contact_preson]`,id)
    //     }
    //     ids.push(id)
    // }
  })

  for (var pair of form.entries()) {
    console.log(pair[0] + ", " + pair[1])
  }

  $.ajax({
    type: "POST",
    url,
    data: form,
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    success: function (res) {
      location.reload()
      if (prestashop.language.iso_code === "pl") {
        new Notification("success", "pomyślnie zmieniono dane")
      } else {
        new Notification("success", "Change data successed")
      }
    },
    error: function () {
      new Notification("danger", "coś poszło nie tak :(")
    },
  })
})

$('[data-link-action="update-customer-types"]').on("click", function (e) {
  e.preventDefault()
  $this = $(this)
  var url = $this.data().url
  var target = $this.data().target
  var customers = []
  var form = new FormData()
  const selects = $(target).find("select")[0]
  // selects.each(function(){
  //     const name = $(this).attr('name')
  //     const val = $(this).val()
  //     // console.log('customer',id,name,val)
  //     form.append(name,val)
  // })
  const inputs = $(target).find("input")
  const ids = []
  inputs.each(function () {
    console.log($(target).find('[data-row="new"]'))

    const id = $(this).data().id
    const name = $(this).attr("name")
    const val = $(this).val()
    const type = $this.data().type
    form.append(`data[${id}][${name}]`, val)

    if (!ids.includes(id)) {
      form.append(`data[${id}][type]`, type)
      console.log(type)
      if (type !== "allegro_shop_type" && type !== "other_shop_type") {
        form.append(`data[${id}][country]`, $(selects).val())
      }
      ids.push(id)
    }
  })

  for (var pair of form.entries()) {
    console.log(pair[0] + ", " + pair[1])
  }

  console.log($this.data().init)
  $.ajax({
    type: "POST",
    url,
    data: form,
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    success: function (res) {
      location.reload()
      if (prestashop.language.iso_code === "pl") {
        new Notification("success", "pomyślnie zmieniono dane")
      } else {
        new Notification("success", "Change data successed")
      }
    },
    error: function () {
      new Notification("danger", "coś poszło nie tak :(")
    },
  })
})

//FILES

// DELETE's

$('[data-action="delete-file"]').on("click", function () {
  $this = $(this)
  var url = $this.data().url
  var id_address = $this.data().addressId
  var form = new FormData()
})

//DELETE CONTACTPPL
$(".js-delete").on("click", function () {
  const $this = $(this)
  const url = $this.data().url
  const model = $this.data().model
  const id = $this.data().id
  const form = new FormData()

  form.append("id", id)
  form.append("model", model)
  console.log("model", id)

  $.ajax({
    type: "POST",
    url,
    data: form,
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    success: function (res) {
      console.log(res)
      $(`[data-row="${id}"`).remove()
      if (prestashop.language.iso_code === "pl") {
          let n = new Notification("success", "pomyślnie usunięto osobę")
          let notificationSuccess = document.querySelector("li.alert.alert-success")
          setTimeout(() => notificationSuccess.remove(), 4000)
      } else {
          let n = new Notification("success", "Contact people was deleted")
          let notificationSuccess = document.querySelector("li.alert.alert-success")
          setTimeout(() => notificationSuccess.remove(), 4000)
      }
    },
  })
})

function getRandomInt(min) {
  min = Math.ceil(min)
  return Math.floor(Math.random() * (6000 - min)) + min
}
