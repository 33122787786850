$(".customer-action-trigger").next().addClass("collapsed").slideUp()

$("body").on("click", '.customer-action-trigger', function(event){
        event.preventDefault()
        const  currentClass = $(this).next().prop('class')
        if (currentClass === "dropdown customer-action expanded") {
            $(this).next().removeClass("expanded")
            $(this).next().addClass("collapsed")
            $(this).next().slideUp()
            const top = $(".header-bottom").outerHeight()
            $(this).next().css("top",top+"px")
        } else {

            const top = $(".header-bottom").outerHeight()
            $(this).next().css("top",top+"px")
            $(".expanded").slideUp().addClass("collapsed").removeClass("expanded")
            $(this).next().removeClass("collapsed")
            $(this).next().addClass("expanded")
            $(this).next().slideDown()
        }


})

$("body").on("click", '[data-trigger="show-sub-menu-tree"]', function(){
    $this = $(this)
        console.log('click tree')

    console.log($this.find('span'))
    if ($('#' + $this.data('target')).is(':visible')) {
    }
    $this.toggleClass('reverse')

    console.log(('#' + $this.data('target')))
    $('#' + $this.data('target')).fadeToggle("fast")
})


$("body").on("click", '[data-trigger="show-mobile-menu"]', function(){
    $this = $(this)

    console.log(('#' + $this.data('target')))
    if($this.data('toggle') === 'open'){
        $this.data('toggle','close')
        $('#' + $this.data('target')).css("transform","translateX(-100%)")
    }else{
        $('#' + $this.data('target')).css("transform","translateX(0)")
        $this.data('toggle','open')
    }
})


$("body").on("click", '[data-trigger="show-search-bar"]', () => {
    const fromWrapper = $('.search-widget-box')
    const form = $('.search-form')
    // console.log(all)
    // all.hide()
    if( $(fromWrapper).css('display') == 'none'){
        $(fromWrapper).css('display','flex')
    }else{
        $(fromWrapper).css('display','none')
    }

    if( $(form).css('display') == 'none'){
        $(form).css('display','flex')
    }else{
        $(form).css('display','none')
    }
})




