export default class Notification{
    constructor(type,msg) {
        console.log('init notification')
        this.type = type
        this.msg = msg
        this.wrapper = $('#notifications')
        this.show()
        this.remove()
    }
   show(){
        this.notification = $('<li>').text(this.msg)
       this.notification.addClass(`alert alert-${this.type}`)
       console.log(this.notification)
       this.wrapper.append(this.notification)

   }
   remove(){
    this.notification = $('<li>').text(this.msg)
    setTimeout(() => this.notification.remove(),4000)
   }
}

