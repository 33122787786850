
const random = Math.floor((Math.random() * 15) + 1)


$('.wrapper-background').each(function(e){
    $this = $(this)
        let path
    const page = prestashop.page.page_name
 const newHeight = $('.wrapper').height()/3
    const backgroundId = localStorage.getItem('aquaray_backgroundId')
    if(page ==='cart' && backgroundId !== null){
        path = `bg${backgroundId}`
    }else{
        path = `bg${random}`
        localStorage.setItem('aquaray_backgroundId',random)
    }


    if(newHeight < 458){
        $this.height(newHeight)
    }
    $this.addClass(path)

})


