import customQuantity from './quantity'

export class OrderPanel {
  constructor() {
    // console.log('ORDER PANEL CONSTRUCT')
    this.text = {pl:'Powiadom mnie, gdy produkt będzie dostępny',en:'Notification when product will be available '}
    this.alertText = {pl:'Produkt jest już obserwowany',en:'Product is already follow'}
    this.initCount = 30
    this.itemOnPage = 15
    this.totalProductsCount = 100
    this.isLoading = false
    this.loading = $('.js-loading')
    this.API = $('.order-panel__products').data('link')
    this.container = $('.cart-items')
    this.filters = { c: '', c1: '', b: '', n: '' }
    this.URL = 'index.php?controller=Products&module=cg_order_panel&fc=module'
    this.AlertURL = 'index.php?controller=Alert&module=cg_order_panel&fc=module'
    // this.bindScroll()
    this.bindFilters()
    this.getProduct()

    $(document).on('click', '.js-mailalert', (e) => {
      e.stopPropagation()
      this.addNotification(e)
    })
    // this.getFiltersValue()
  }

 

  clearFilter() {
    $('.js-order-manufacture').val(null).trigger('change.select2')
    $('.js-order-category').val(null).trigger('change.select2')
    $('.js-order-subcategory').val(null).trigger('change.select2')
    this.filters = { c: '', c1: '', b: '', n: '' }
    $('.n').val('')
    this.getProduct()
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName]
      }
    }
  }
  setFilters(obj) {
    this.filters[obj.filter] = obj.val
    if (obj.filter === 'c1') if (obj.val !== '-') this.filters['c'] = obj.val
    this.getProduct()
  }
  bindFilters() {
    var that = this
    $('.panel-filters').each(function (e) {
      $(this).change(function () {
        that.setFilters({
          val: $(this).val(),
          filter: $(this).data('filter'),
        })
      })
    })
  }
  bindScroll() {
    $(window).scroll((e) => {
      if (
          Math.ceil($(window).scrollTop() + $(window).height()) >=
          $(document).height() - $('#footer').height()
      ) {
        console.log('bottom')
        if (this.initCount === this.totalProductsCount || this.isLoading) {
          return
        }
        // console.log(this.initCount)
        // console.log(this.itemOnPage)
        // console.log(this.totalProductsCount)
        if (this.initCount + this.itemOnPage > this.totalProductsCount) {
          this.initCount = this.totalProductsCount
        } else {
          this.initCount += this.itemOnPage
        }
        // debugger
        this.getProduct()
      }
    })
  }
  isInCart(id) {
    const res = prestashop.cart.products.findIndex(
        (e) => String(id) === e.id_product
    )
    return res
  }
  generateDiscount(val, price, id, data) {
    const its =
        this.isInCart(id) > -1
            ? parseInt(prestashop.cart.products[this.isInCart(id)].cart_quantity)
            : parseInt(data.minimal_quantity)
    console.log(its)
    console.log($(`quantity_wanted-${id}`))
    const td = $(` <td class="cart-item-discount text-center"></td>`)
    if (val === null || price * its < val.minimal_amount) {
      td.append('<span class="icon__minus"></span>')
    } else {
      td.append(
          `<span class="cart-item-discount__value">${val.display_discount}%</span>`
      )
    }
    return td
  }
  generatePriceColor(price, discount, currency, data) {
    const its =
        this.isInCart(data.id_product) > -1
            ? parseInt(
            prestashop.cart.products[this.isInCart(data.id_product)]
                .cart_quantity
            )
            : parseInt(data.minimal_quantity)

    const tr = $(
        ' <td class="cart-item-price-brutto text-center position-relative"></td>'
    )

    if (!discount) {
      tr.append(`<span class="price price__blue">
                                      ${price} ${currency} </span>`)
      return tr
    } else if (price * its >= discount.minimal_amount) {
      tr.append(`
    <span class="price price__gray">${price} ${currency} </span> 
    <span class="price price__yellow">${
          (price * discount.discount).toFixed(2)
      } ${currency} </span>`)
    } else {
      tr.append(`<span class="price price__blue">
                                      ${price} ${currency} </span>`)
    }

    return tr
  }

  addNotification(e) {
    var ids = $('div.js-mailalert > input[type=hidden]')
    console.log(e.target.dataset)

    const id = e.target.dataset.productId
    console.log(id)

    $.ajax({
      type: 'POST',
      url: $(`div.js-mailalert`).data('url'),
      data: 'id_product='+id+'&id_product_attribute=0&customer_email='+$('div.js-mailalert > input[type=email]').val(),
      success: function (resp) {
        resp = JSON.parse(resp)
        const element = document.querySelector(`.js-mailalert[data-id-product="${id}"]`)
        // $(`div.js-mailalert > span`).html('<article class="alert alert-info" role="alert" data-alert="success">'+resp.message+'</article>').show()
        element.innerHTML = '<article class="alert alert-info" role="alert" data-alert="success">'+resp.message+'</article>'
        if (!resp.error) {
          $('div.js-mailalert > input[type=email]').hide()
          $('div.js-mailalert > #gdpr_consent').hide()
        }
      }
    })
    return false
  }

  generateRow(data) {
    const tr = $(
        `<tr class="cart-item" data-product-id="${data.id_product}"> </tr>`
    )
    tr.append(`<td class="cart-item-description">   <img class="mr-3 pl-2" src="${data.cover_image}" alt="${data.name}">
                   <a class="cart-item-description--wrapper" href="${data.link}">${data.name}</a>  </td>`)
    tr.append(
        this.generateDiscount(data.discount, data.price, data.id_product, data)
    )

    tr.append(
        `<td class="text-center cart-item-price-netto">${data.price} ${data.currency}</td>`
    )

    tr.append(`<td class="cart-item-vat text-center">
                       ${data.tax_rate}% </td>`)

    tr.append(
        this.generatePriceColor(
            data.price_with_tax,
            data.discount,
            data.currency,
            data
        )
    )

    if(data.retail_price == null) {
      tr.append(`<td class="cart-item-price-detalic text-center">
                    
                    </td>`)
    }
    else {
      tr.append(`<td class="cart-item-price-detalic text-center">
                     ${data.retail_price} ${data.currency}
                    </td>`)
    }

    if(data.available_quantity > 0){
      tr.append(` <td class="cart-item-qty text-center">
                        <div class="quantity-input  pt_Quantity">
                            <div class="pt_Quantity-input">
                                <input
                                        type="text"
                                        name="qty"
                                        data-number="${
          this.isInCart(data.id_product) > -1
              ? parseInt(
              prestashop.cart.products[
                  this.isInCart(data.id_product)
                  ].cart_quantity
              )
              : parseInt(data.minimal_quantity)
      }"
                                        id="quantity_wanted-${data.id_product}"
                                        value="${
          this.isInCart(data.id_product) > -1
              ? parseInt(
              prestashop.cart.products[
                  this.isInCart(data.id_product)
                  ].cart_quantity
              )
              : parseInt(data.minimal_quantity)
      } ${prestashop.language.iso_code === 'pl' ? 'szt.' : 'pcs'}"
                                        class="pt_QuantityInput"
                                        data-value="qty-for-product"
                                        min="${parseInt(data.minimal_quantity)}"
                                        max="${data.available_quantity}"
                                        aria-label="ilosć"
                                ></div>


                        </div>`)
    }else{

      const assignId = () => {
        const inputs = [...document.querySelectorAll('.valueInput')]
        const mails = [...document.querySelectorAll('.js-mailalert')]

        inputs.forEach(input => {
          const parent = input.parentElement.parentElement.parentElement.parentElement.parentElement

          const idOfProduct = parent.dataset.productId

          // console.log(idOfProduct)
          input.setAttribute('value',idOfProduct)
        })

        mails.forEach(mail => {
          const parent = mail.parentElement.parentElement.parentElement.parentElement

          const idOfProduct = parent.dataset.productId
          mail.setAttribute('data-product-id',idOfProduct)
        })
      }

      assignId()

      if(data.show_alert) {
        tr.append(          `<td class="cart-item-alert text-center"> <div class="tabs cart-item-price-detalic text-center">
              <form>
        <div class="js-mailalert" data-id-product="${data.id_product}" style="text-align:center;" data-url="index.php?process=add&fc=module&module=ps_emailalerts&controller=actions&id_lang=1">
        <input type="hidden" value="" class="valueInput"/>
        <input type="hidden" value="0"/>
            <button class="clear_button clear_button--event" type="button" rel="nofollow" data-product-id="${data.id_product}">
                <span data-product-id="${data.id_product}" class="icon__alert"></span>
                ${this.text[prestashop.language.iso_code]}
            </button>
            <span style="display:none;padding:5px"></span>
        </div>
    </form>
    </td>
    
`)
      }
    else {
        tr.append(` <td class="cart-item-alert text-center"><div class="clear__button">
        ${this.alertText[prestashop.language.iso_code]}
                    </button>         
        </div></td>`)
      }
    }

    
    if(data.available_quantity > 0){
      if (this.isInCart(data.id_product) > -1) {
        tr.append(` <td class="delete">
                                 <span data-id-product="${data.id_product}" data-cart-action="remove-product" class="icon__remove-panel"></span>
                              </td>`)
      } else {
        tr.append(` <td class="delete">
                                <span data-cart-action="add-product" data-id-product="${data.id_product}" class="icon__cart-panel"></span>
                              </td>`)
      }
    }else{
      tr.append(` <td class="">
 
                                <span  class="icon__empty-cart"></span>
                              </td>`)
    }

    this.container.append(tr)
  }
  

  getProduct() {
    this.container.empty()
    this.loading.show()
    $('.js-empty-load').hide()

    this.isLoading = true
    this.clean(this.filters)
    $.ajax({
      url: this.API,
      data: {
        count: this.initCount,
        ...this.filters,
      },
      success: (response) => {
        console.log(response)
        this.totalProductsCount = response.total_products_count
        if (response.products.length === 0) {
          this.loading.hide()
          $('.js-empty-load').show()
          return
        }
        console.log(response.products)

        response.products.forEach((product) => {
          this.generateRow(product)
        })
        this.loading.hide()
        this.container.animate(
            {
              scrollTop: $(this.container).offset().top,
            },
            1000
        )
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
        customQuantity()
      },
      error: function () {
        console.log('Wystąpił błąd z połączeniem')
      },
    })
  }

  //     ADDING SECTION
}

$('body').on('click', '[data-cart-action="add-product"]', function () {
  const $this = $(this)
  const id = $this.data().idProduct
  const row = $(`[data-product-id=${id}]`)
  const qty = $(row).find('.pt_QuantityInput').data().number
  $.ajax({
    url: prestashop.urls.pages.cart,
    type: 'POST',
    data: {
      token: prestashop.static_token,
      id_product: id,
      add: 1,
      qty: qty,
      action: 'update',
      ajax: false,
      id_customization: 0,
    },
    success: function (response) {
      response = JSON.parse(response)
      if (!response.hasError) {
        $('.shopping-cart-box').hide('fast')
        prestashop.cart = response.cart
        changeShoppingCart(response, id, row, 'add')
        // generateShoppingCart()

        //
        console.log('done add')
      }
      // prestashop.emit('updateCart', {
      //     reason: {}, resp: response
      // })
    },
  })
})

$('body').on('click', '[data-cart-action="remove-product"]', function () {
  const $this = $(this)
  const id = $this.data().idProduct
  const row = $(`[data-product-id=${id}]`)
  const qty = $(row).find('.pt_QuantityInput').data().number
  console.log($this.data().idProduct)
  $.ajax({
    url: prestashop.urls.pages.cart,
    type: 'POST',
    data: {
      token: prestashop.static_token,
      id_product: id,
      delete: 1,
      qty: qty,
      action: 'update',
      ajax: false,
      id_customization: 0,
    },
    success: function (response) {
      response = JSON.parse(response)
      if (!response.hasError) {
        $('.shopping-cart-box').hide('fast')
        prestashop.cart = response.cart
        changeShoppingCart(response, id, row, 'remove')
        // generateShoppingCart()

        //
      }
      // prestashop.emit('updateCart', {
      //     reason: {}, resp: response
      // })
    },
  })
})

function changeShoppingCart(res, id, row, type) {
  const rowAction = [
    ` <span data-cart-action="add-product" data-id-product="${id}" class="icon__cart-panel"></span>
`,
    ` <span data-id-product="${id}" data-cart-action="remove-product" class="icon__remove-panel"></span>
`,
  ]

  var cart = $('.cart-products-count')
  cart.addClass('animate')
  setTimeout(() => {
    cart.removeClass('animate')
  }, 1000)
  cart.html(res.cart.products_count)

  if (type === 'add') {
    row.find('.delete').html(rowAction[1])
  } else {
    row.find('.delete').html(rowAction[0])
  }
}

$('.js-order-category').change((e) => {
  console.log(e.target.value)
  setSubCategories(e.target.value)
})

function setSubCategories(id) {
  const s = $('.js-order-subcategory')
  let mapping = subCategories
  if (id !== '-') {
    mapping = subCategories.filter((e) => e.id_parent === id)
  }
  s.empty()
  s.append(`<option value="-">-</option>`)
  mapping.forEach((m) => {
    s.append(`<option value="${m.id_category}">${m.name}</option>`)
  })
}
