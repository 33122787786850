// $(() => {
//     let $form = $('customer-form')
//
//     $form.on('submit', function() {
//         $form = $(this).closest('form')
//         console.log($form)
//         validate($form)
//     })
//
// })
//
// function validate ($form,event) {
//     console.log($form)
//     if ($form[0].checkValidity() === false) {
//         event.preventDefault()
//         event.stopPropagation()
//     }
//     $form.addClass('was-validated')
//     checkPasswordField()
// }
//
// function checkPasswordField () {
//     const $password = $('.form-password')
//     if ($password.length) {
//         const isInvalid = $password.find('input[type=password]:invalid').length
//         const $fieldComment = $password.next('.form-control-comment')
//
//         if (isInvalid) {
//             $fieldComment.addClass('text--red')
//         } else {
//             $fieldComment.removeClass('text--red')
//         }
//     }
// }
import {currentMessages} from './translations'
const remote = {remote:{
        url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
        type:'get',
        data:{
            iso_code:function(){
                return $('#companyCityCode').data().isoCode
            },
            value:function(){
                return $('#companyCityCode').val()
            }
        },
        dataFilter: function(data) {
            var json = JSON.parse(data)
            return json.valid

        }
    }
}

$(() => {
    // const validationMassages = {
    //     en:{
    //         required:'This field is required',
    //         uniqueEmail:"This email is taken already",
    //         validMail:"Please enter a valid email address",
    //         validPostCode:"Pleas enter a  valid post code for selected country",
    //         validTin:"Pleas enter a  valid tin (10 numbers)",
    //
    //     },
    //     pl:{
    //         required:'To pole jest wymagane',
    //         uniqueEmail:'Wybrany mail znajduje się w naszej bazie klientów',
    //         validMail:"Wprowadź poprawny adres email",
    //         validPostCode:"Wprowadź odpowiedni kod pocztowy dla wybranego kraju",
    //         validTin:"Wprowadź poprawny numer nip (10 cyfr)",
    //
    //     }
    // }
    // const lang = prestashop.language.iso_code
    // const currentMessages  = validationMassages[lang]
    console.log(currentMessages)
    setTimeout(()=>{
        $('[name*="stationaryShopPostCode"]').each(function(e) {

            const $this = $(this)
            $this.rules("add",
                {
                    remote:{
                        url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
                        type:'get',
                        data:{
                            iso_code:function(){
                                return $this.data().isoCode
                            },
                            value:function(){
                                return $this.val()
                            }
                        },
                        dataFilter: function(data) {
                            var json = JSON.parse(data)
                            return json.valid

                        }
                    },
                    messages:{
                        remote:currentMessages.validPostCode
                    },
                })
        })

        $('[name*="onlineShopPostCode"]').each(function() {
            const $this = $(this)
            console.log($this)

            $this.rules("add",
                {
                    remote:{
                        url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
                        type:'get',
                        data:{
                            iso_code:function(){
                                return $this.data().isoCode
                            },
                            value:function(){
                                return $this.val()
                            }
                        },
                        dataFilter: function(data) {
                            var json = JSON.parse(data)
                            return json.valid

                        }
                    },
                    messages:{
                        remote:currentMessages.validPostCode
                    }
                })
        })
    },1760)


    // Initialize form validation on the registration form.
    // It has the name attribute "registration"
    let response
    $.validator.addMethod("uniqueEmail",
        (value, element) => {
            $.ajax({
                type: "GET",
                url: `${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyEmail&email=${value}`,
                dataType:"json",
                success: function(msg)
                {
                    console.log(msg)
                    //If username exists, set response to true
                    response = ( msg.used ===false ) ? true : false
                }
            })
            return response
        }, "* This email is taken already ")

    // jQuery.validator.addMethod("tinValid", (value, element) => {
    //     return value.length === 10
    // }, "*  tin must have 10 char")

    jQuery.validator.addMethod("validPostCode", (value, element) => {
        const isoCode = $(element).data().isoCode
        $.ajax({
            type: "GET",
            url: `${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-PostCode&iso_code=${isoCode}&value=${value}`,
            dataType:"json",
            success: function(msg)
            {
                console.log(msg)
                //If username exists, set response to true
                response = ( msg.used ===true ) ? true : false
            }
        })
        console.log(response)
        return response
    }, "* This email is taken already ")

    jQuery.validator.addMethod("accept", (value, element, param) => {
        return value.match(new RegExp("^" + param + "$"))
    })

    jQuery.validator.addMethod("customemail", 
    (value, element) => {
            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
    })

  const valid =   $("#customer-form").validate({

        errorPlacement: function(label, element) {
            label.addClass('grid-error')
            label.insertAfter(element)
        },
        highlight: function(element) {
            console.log(element)
            if($(element).hasClass('phone-number-fv')){
                $(element).next().addClass('grid-error')
                $(element).parent().parent().parent().addClass("field-error")
            }else{
                $(element).parent().parent().addClass("field-error")

            }
        },
        unhighlight: function(element) {
            if($(element).hasClass('phone-number-fv')){
                $(element).parent().parent().parent().removeClass("field-error")
            }else{
                $(element).parent().parent().removeClass("field-error")

            }
        },
        debug:false,
        errorClass: "invalid",
        errorElement: "div",
        focusInvalid: false,
        focusCleanup: true,
        invalidHandler: function(form, validator) {
            console.log('ERRORS!')
            console.log(validator.numberOfInvalids())
            for (let i in validator.errorMap) {
                console.log(i, ":", validator.errorMap[i])
            }
            if (!validator.numberOfInvalids())
                return

            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).offset().top -320
            }, 1000)
        },
        wrapper: "div",
        errorLabelContainer: "#messageBox",

        // Specify validation rules
        rules: {

            vat_number:{
                required:true,
            },
            companyCityCode:{
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
                    type:'get',
                    data:{
                      iso_code:function(){
                          return $('#companyCityCode').data().isoCode
                      },
                      value:function(){
                          return $('#companyCityCode').val()
                      }
                    },
                    dataFilter: function(data) {
                        var json = JSON.parse(data)
                        return json.valid

                    }
                }
            },
            'stationaryPostCode[]':{
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=VerifyPostcode&`,
                    type:'get',
                    data:{
                        iso_code:function(){
                            return $('#companyCityCode').data().isoCode
                        },
                        value:function(){
                            return $('#companyCityCode').val()
                        }
                    },
                    dataFilter: function(data) {
                        var json = JSON.parse(data)
                        return json.valid

                    }
                }
            },
            email: {
                required: true,
                email: true,
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
                    type:'get',
                    data:{
                        value:function(){
                            return $('#companyEmail').val()
                        }
                    },
                    dataFilter: function(data) {
                        const json = JSON.parse(data)
                        return json.used === false

                    },
                },
                // accept: "[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, }"
                customemail: true
                
            },
            'customerEmail[0]': {
                required: true,
                email: true,
                customemail: true
                
            },
            'customerEmail[1]': {
                required: true,
                email: true,
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
                    type:'get',
                    data:{
                        value:function(){
                            return $('#companyEmail').val()
                        }
                    },
                    dataFilter: function(data) {
                        const json = JSON.parse(data)
                        return json.used === false

                    },
                },
                customemail: true
 
                
            },
            'customerEmail[2]': {
                required: true,
                email: true,
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
                    type:'get',
                    data:{
                        value:function(){
                            return $('#companyEmail').val()
                        }
                    },
                    dataFilter: function(data) {
                        const json = JSON.parse(data)
                        return json.used === false

                    },
                },
                customemail: true
 
                
            },
            'customerEmail[3]': {
                required: true,
                email: true,
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
                    type:'get',
                    data:{
                        value:function(){
                            return $('#companyEmail').val()
                        }
                    },
                    dataFilter: function(data) {
                        const json = JSON.parse(data)
                        return json.used === false

                    },
                },
                customemail: true
 
                
            },
            'customerEmail[4]': {
                required: true,
                email: true,
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
                    type:'get',
                    data:{
                        value:function(){
                            return $('#companyEmail').val()
                        }
                    },
                    dataFilter: function(data) {
                        const json = JSON.parse(data)
                        return json.used === false

                    },
                },
                customemail: true
 
                
            },
            'customerEmail[5]': {
                required: true,
                email: true,
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Email&`,
                    type:'get',
                    data:{
                        value:function(){
                            return $('#companyEmail').val()
                        }
                    },
                    dataFilter: function(data) {
                        const json = JSON.parse(data)
                        return json.used === false

                    },
                },
                customemail: true
 
                
            },

            companyPhone:{
                required:true,
            },
            password: {
                required: true,
                minlength: 5
            }
        },
        // Specify validation error messages
        messages: {
            email:{
                customemail: currentMessages.validMail,
                // accept: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            
            'customerEmail[0]': {
                customemail: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            'customerEmail[1]': {
                customemail: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            'customerEmail[2]': {
                customemail: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            'customerEmail[3]': {
                customemail: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            'customerEmail[4]': {
                customemail: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            'customerEmail[5]': {
                customemail: currentMessages.validMail,
                email: currentMessages.validMail,
                required: currentMessages.validMail,
                uniqueEmail:currentMessages.uniqueEmail,
                remote:currentMessages.uniqueEmail
            },
            companyPhone:currentMessages.validPhone,
            vat_number:{
                required:currentMessages.required,
                tinValid:currentMessages.validTin
            },
            companyCityCode:{
                remote:currentMessages.validPostCode
            }
        },

        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function(form) {
            console.log(form)
            console.log('valid all')
            form.submit()
        }
    })
    console.log($('#customer-form').validate())
    console.log($('.contactPeople').validate())
    console.log($('.customerContactEdit').validate())

    $.validator.messages.required = currentMessages.required
    $.validator.messages.email = currentMessages.validEmail

    // $('form[name="registration"]').on('submit', function(event) {
    //     console.log('on submit')
    //     console.log(valid.valid())
    //     if(valid.valid()){
    //         $(this).submit()
    //     }
    // })

})

