const validationMassages = {
    en:{
        required:'This field is required',
        uniqueEmail:"This email is taken already",
        validMail:"Please enter a valid email address",
        validPostCode:"Pleas enter a  valid post code for selected country",
        validTin:"Pleas enter a  valid tin (10 numbers)",

    },
    pl:{
        required:'To pole jest wymagane',
        uniqueEmail:'Wybrany mail znajduje się w naszej bazie klientów',
        validMail:"Wprowadź poprawny adres email",
        validPostCode:"Wprowadź odpowiedni kod pocztowy dla wybranego kraju",
        validTin:"Wprowadź poprawny numer nip (10 cyfr)",

    }
}
const lang = prestashop.language.iso_code
const currentMessages  = validationMassages[lang]
console.log(currentMessages)

export {validationMassages,currentMessages}
