




import {currentMessages} from './translations'
import Notification from "./notifications"


let files = []



$('body').on('click','[data-trigger="add-section"]', function() {

    const target = $(this).data('section')
        const elements = $(`[data-register-section="${target}"]`)[0]
    let index = parseInt($(elements).data('index'))
        index++
    $(elements).data('index',index)
    // console.log(index)
    // console.log($(elements))
    const childrenCount =  elements.children.length
    // console.log(childrenCount)
    // console.log(elements)
    const oldForms  = $(elements.firstElementChild).html()
    const newForms = oldForms.replace(/\[[^\]]+\]/g,`[${index}]`)
    const showButton = newForms.replace('d-none','')
        // const delButton = newForms.find('.delete-ppl')
    console.log(showButton)
    const cont = $('<div data-index="'+index+'" class="form-section"> </div>').append(showButton)
    cont.prepend('<hr>')

    $(elements).append(cont)

    console.log( $(elements))
    console.log(`[data-register-section="${target}"]`)

    $('[name*="stationaryShopPostCode"]').each(function() {
        const $this = $(this)
        console.log($this)

        $this.rules("add",
            {
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-PostCode&`,
                    type:'get',
                    data:{
                        iso_code:function(){
                            return $this.data().isoCode
                        },
                        value:function(){
                            return $this.val()
                        }
                    },
                    dataFilter: function(data) {
                        var json = JSON.parse(data)
                        return json.valid

                    }
                },
                messages:{
                    remote:currentMessages.validPostCode
                }
            })
    })
    $('[name*="onlineShopPostCode"]').each(function() {
        const $this = $(this)
        console.log($this)

        $this.rules("add",
            {
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-PostCode&`,
                    type:'get',
                    data:{
                        iso_code:function(){
                            return $this.data().isoCode
                        },
                        value:function(){
                            return $this.val()
                        }
                    },
                    dataFilter: function(data) {
                        var json = JSON.parse(data)
                        return json.valid

                    }
                },
                messages:{
                    remote:currentMessages.validPostCode
                }
            })
    })
})
// $('.selectStationaryCountry').trigger('change')
$('body').on('click','.selectStationaryCountry',function () {
    const iso = $(this).children("option:selected").data().isoCode
    console.log(iso)
    const pc=$(`input[name="${$(this).data().for}"]`)
    // const pc=$('input[name="companyCityCode"]')
    console.log($(pc).data())
    $(pc).data('iso-code',iso)
    $(pc).val('')
})
// $('.selectStationaryCountry').on('change',function(e){
//     // console.log($(this).data())
//
// })


$('body').on('click','[data-show-section="addNext"]',function() {
   const target = $('.'+$(this).data().sectionTarget)
    console.log(target.find(' .required'))

 if($(this).is(':checked')){
     $('.'+$(this).data().sectionTarget).show()
     console.log('shooow')
     target.find(' .required').each(function () {
         $(this).prop('required',true)
     })
 }
 else{
     $('.'+$(this).data().sectionTarget).hide()
     target.find(' .required').each(function () {
         $(this).prop('required',false)
     })
 }
})





$('#uploadFiles').on('change',function () {
    const list = $(this)[0].files

    $('.file-list').empty()

    Object.values(list).forEach(file=>{
        files.push(file)

        $('.file-list').append($('<li>'+file.name+'</li>'))
        console.log(file.name)
    })
})

$('[data-link-action="update-customer-files"]').on('click',function(e){
    e.preventDefault()
    $this =$(this)
    var url = $this.data().url
    var id_address= $this.data().addressId
    var form = new FormData()

    var totalfiles = document.getElementById('uploadFiles').files.length

    for (var index = 0; index < totalfiles; index++) {
        form.append("uploadedFiles[]", document.getElementById('uploadFiles').files[index])
    }

    // const input = [...$("#uploadFile")[0].files]
    //     console.log(input)
    // Object.values(input).forEach(i=>{
    //     console.log(i)
    //     form.append('uploadedFiles[]',i)
    // })

    // inputs.each(function(){
    //     console.log($(this))
    //     const name = $(this).attr('name')
    //     const val = $(
    //     console.log('customer',name,val)
    //     form.append(name,val)
    // })

    for (var pair of form.entries()) {
        console.log(pair[0]+ ', ' + pair[1])
    }

    var data = new FormData()

    files.forEach((file, i) => {
        data.append(file.name, file)
    })

    $.ajax({
        type: "POST",
        url,
        data: data,
        processData: false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        success: function(res){
            new Notification('success','pomyślnie zmieniono dane')
            location.reload()
        },
        error:function(){
            let closeNot = new Notification("danger", "coś poszło nie tak :(")
            const errorNot = document.querySelector("li.alert.alert-danger")
            setTimeout(() => errorNot.remove(), 4000)

        }
    })

})





$('input:radio[name="ceidg"]').change(function(){
    if($(this).val() == 'notHave'){
        $('[data-show-input="attachment"]').hide()
    }else{
        $('[data-show-input="attachment"]').show()

    }
})
$('.clearFiles').on('click',() => {

        $('#uploadFiles')[0].value = ''
        $('.file-list').empty()
})



function setPrefixPhone(field,target){
    const prefix = $(target).children("option:selected").data().prefix
    console.log(prefix)

    $(field).each(function() {
        $(this).val(prefix)
        // const prefixTarget = $(field).next()[0]
        // $(prefixTarget).text("+"+prefix)
    })


}

$('.phonePrefixFv').change(function() {
    const prefix = $(this).children("option:selected").data().prefix
    const iso = $(this).children("option:selected").val()

    console.log(prefix)

   const t = $('select[name="companyPhonePrefix"]')
    const t1 = $('select[name="customerPhonePrefix"]')

    $(t).val(prefix)
    $(t1).val(prefix)

    // console.log($(t))
    // console.log(t)
        // setPrefixPhone('.phone-number-fv',this)

    const pc=$('input[name="companyCityCode"]')
    console.log(pc.data())
    $(pc).data('iso-code',iso)
    $(pc).val('')


})

$(document).ready(() => {
    // console.log('test')
    // setPrefixPhone('.phone-number-fv','.phonePrefixFv')
})

function cpValTo(value,name){
    console.log(name)
    const target =$(`input[name="${name}"]`)[0]
        $(target).val(value)
}

// COPY FV DATA TO STATIONARY SHOP

$('.copy-fv-data').click(function(){
    console.log(this)
        const attrs = ['company','companyAddress','companyCityCode','companyCity','companyProvince']
        const targets = ['Name[0]','Address[0]','PostCode[0]','City[0]','Region[0]']
        // const values = []
        attrs.forEach((attr,idx)=>{
            const target =$(`input[name="${attr}"]`)[0]
            const val = $(target).val()
            console.log(val)
                cpValTo(val,`stationaryShop${targets[idx]}`)
            // console.log($(`input[name="${attr}"]`).val())
            // values.push({[attr]:val})
        })

    const target =$(`select[name="companyCountry"]`)[0]
        console.log(target)
    const t  =$(`select[name="stationaryShopCountry[0]"]`)[0]
    console.log(t)
    console.log($(t).val())
        $(t).val($(target).val()).change()
})

$('body').on('click','[data-action="delete"]',function(e){
    const parent = $(this).parent().parent()
    console.log(parent)
    if(parent.hasClass('form-section')){
        parent.remove()
    }
})

$('body').on('click','.delete-ppl',function () {
    const idx = $(this).data().delete

    const registerSection = $(this).data().registerTarget
            console.log(idx[0])
    // const formated  = idx.match(/(\d+)/)
    //     console.log(formated)
        const element = $(`.${registerSection}`)

    const t = $(element).children(`[data-index="${idx[0]}"]`)

    t.remove()
    let index = parseInt($(element).data('index'))
    index--
    $(element).data('index',index)
    // debugger

    console.log(element)
})