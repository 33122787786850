function customQuantityWithLink() {

    jQuery(".pt_Quantity").each(function() {
        console.log('pt_init')
        var spinner = jQuery(this),
            input = spinner.find('input'),
            btnUp = spinner.find(".pt_QuantityUp"),
            btnDown = spinner.find(".pt_QuantityDown"),
            min = input.attr("min"),
            max = input.attr("max"),
            currentValue = parseInt(input.data('number')),
        valOfAmout = input.val(),
            newVal = 0


        spinner.find('input').on('change',(e) => {
            if(parseInt(e.target.value) <=max){
                input.data('number',parseInt(e.target.value))
            }else{
                input.data('number',parseInt(max))

            }

        })

        spinner.find('input').blur(function(){
         
            $(this).attr('type','text')

            spinner.find("input").val(`${input.data('number')} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)
        })


        spinner.find('input').focus(function(){
            $(this).attr('type','number')

            spinner.find("input").val(`${input.data('number')} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)
        })

        btnUp.on("click", () => {
            var oldValue = parseFloat(input.val())

            if (oldValue >= max) {
                var newVal = oldValue
            } else {
                var newVal = oldValue + 1
            }
            spinner.find("input").data('number', newVal)
            spinner.find("input").val(`${newVal} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)

            spinner.find("input").trigger("change")
        })
        btnDown.on("click", (e) => {
            console.log('down')

            var oldValue = parseFloat(input.data('number'))
            if (oldValue <= min) {
                var newVal = oldValue
            } else {
                var newVal = oldValue - 1
            }
            spinner.find("input").val(`${newVal} ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)
            spinner.find("input").data('number',newVal)
            spinner.find("input").trigger("change")
        })
        spinner.find('input').blur(function(e){
            $(this).attr('type','text')

            var op = 'down'

            var oldValue = currentValue
            var newVal = input.data('number')

            if(newVal >= max) {
                newVal = max
            }

            if(newVal <= min) {
                newVal = min
            }

            var diff = newVal - oldValue

            if(diff > 0) {
                op = 'up'
            }

            diff = Math.abs(diff)

            const urlParams = new URLSearchParams(spinner.find("input").attr("data-link"))
            urlParams.set('op', op)
            urlParams.set('qty', diff)
            urlParams.set('update', diff)
            spinner.find("input").attr("data-link", decodeURIComponent(urlParams.toString()))

            spinner.find("input").val(newVal + ` ${prestashop.language.iso_code==="pl" ? 'szt' :'pcs'}`)
            spinner.find("input").data('number', newVal)
            spinner.find("input").trigger("change")

            if(diff !== 0) {
                $.ajax({
                    type:'GET',
                    url:spinner.find("input").attr("data-link"),
                    success(){
                        location.reload()
                    }
                })
            }
        })
    })
}



export default  customQuantityWithLink
