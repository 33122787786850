import {currentMessages} from "./translations"

$('button[data-trigger="add-new-in-account"]').on('click',function(){
    let id =  parseInt($(this).data().length)+1
    console.log(id)
    $(this).data('length',id)
    $('.ppl').append(`
     <div class="row justify-content-between mb-3">
                                <div class="col-md-5">
                                    <div class="input-wrapper   ">
                                            <label class="input-wrapper__label ">
                                            ${prestashop.language.iso_code==="pl" ? 'Imię i nazwisko' :'Full name'}:                                                
                                            </label>
                                            <div class="">
                                                <input class="input-wrapper-xl__input required" data-id="${id}"  required name="name_surname[${id}]" type="text" value="" >
                                            </div>
                                        </div>
                                    <div class="input-wrapper   ">
                                       
                                            <label class="input-wrapper__label ">
                                            ${prestashop.language.iso_code==="pl" ? 'Adres e-mail' :'E-mail address'}:                                                
                                            </label>
                                            <div class="">
                                                <input class="input-wrapper-xl__input required inputjqcheck"  data-id="${id}"  required name="email[${id}]" type="email" value="" >
                                            </div>
                                            
                                   
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="input-wrapper  ">
                                    

                                            <label class="input-wrapper__label">
                                            ${prestashop.language.iso_code==="pl" ? 'Telefon komórkowy' :'Mobile phone'}:                                                
                                                                </label>
                                            <div class="position-relative">
                                            <div class="phone-input-grid phone-input-${id}">
                                                                                            <input class="input-wrapper-xl__input required" data-id="${id}"  required name="phone[${id}]" type="text" value="" >

                                            </div>
                                            </div>
                                           
                                      
                                    </div>
                                    <div class="input-wrapper   ">
                                       
                                            <label class="input-wrapper__label ">
                                                                                           ${prestashop.language.iso_code==="pl" ? 'Stanowisko' :'Position'}:                                                

                                            </label>
                                            <div class="">
                                                <input class="input-wrapper-xl__input required"  data-id="${id}" required name="position[${id}]" type="text" value="" >
                                            </div>
                                     
                                    </div>

                                </div>

                            </div>`)

    selectPrefix(`.phone-input-${id}`,id)
    addRules()
 
   
    addEmailRules()
})




$('button[data-trigger="add-new-stationary"]').on('click',function(){
    let id =  parseInt($(this).data().length)+1
    console.log(id)
    $(this).data('length',id)
    $('.pushStationary').append(`
     <div class="form-section"  >
                        <div class="row justify-content-between">
                            <div class="col-md-5">
                                <div class="input-wrapper   ">
                                    <label class="input-wrapper__label ">
                                            ${prestashop.language.iso_code==="pl" ? 'Nazwa' :'Name'}:                                                
                                    </label>
                                    <div class="">
                                        <input class="input-wrapper-xl__input required"  required data-id="${id}"  name="name" type="text" value="">
                                    </div>
                                </div>
                                <div class="input-wrapper   ">
                                    <label class="input-wrapper__label ">
                                                                                ${prestashop.language.iso_code==="pl" ? 'Strona www(opcjonalnie)' :'Website(optional)'}:                                                

                                    </label>
                                    <div class="">
                                        <input class="input-wrapper-xl__input "  data-id="${id}" name="website" type="text" value="">
                                    </div>
                                </div>
                                <div class="input-wrapper   ">
                                    <label class="input-wrapper__label ">
                        ${prestashop.language.iso_code==="pl" ? 'Strona Facebook(opcjonalnie)' :'Facebook(optional)'}:                                                

                                    </label>
                                    <div class="">
                                        <input class="input-wrapper-xl__input"  data-id="${id}" name="facebook" type="text" value="">
                                    </div>
                                </div>


                            </div>
                            <div class="col-md-5">
                                <div class="input-wrapper  ">
                                    <label class="input-wrapper__label ">
                                      ${prestashop.language.iso_code==="pl" ? 'Ulica,nr domu/lokalu' :'Street, address number'}:                                                

                                    </label>
                                    <div class="">
                                        <input class="input-wrapper-xl__input required"  data-id="${id}"  name="address1" type="text" value="" >
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-5">
                                        <div class="input-wrapper   ">
                                            <label class="input-wrapper__label ">
                 ${prestashop.language.iso_code==="pl" ? 'Kod pocztowy' :'Post code'}:                                                

                                            </label>
                                            <div class="">
                                                <input class="input-wrapper-xl__input required postcode"  data-id="${id}"  data-iso-code="PL" name="postcode[${id}]" type="text" value="" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-7">
                                        <div class="input-wrapper   ">
                                            <label class="input-wrapper__label ">
          ${prestashop.language.iso_code==="pl" ? 'Miejsowość' :'City'}: 
                                            </label>
                                            <div class="">
                                                <input class="input-wrapper-xl__input required"  data-id="${id}"  name="city" type="text" value="" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="input-wrapper   ">
                                    <label class="input-wrapper__label ">
                         ${prestashop.language.iso_code==="pl" ? 'Województwo' :'Voivodeship'}: 

                                    </label>
                                    <div class="">
                                        <input class="input-wrapper-xl__input "  data-id="${id}" name="voivodeship" type="text" value="" >
                                    </div>
                                </div>
                                
                                    <div class="input-wrapper   ">
                                      
                                            <label class="input-wrapper__label ">
                              ${prestashop.language.iso_code==="pl" ? 'Kraj' :'Country'}: 

                                            </label>
                                            <div class="select-stt-${id}">
                                               
                                            </div>
                                   
                                    </div>

                            </div>


                        </div>
                    </div>
    `)
    select(`.select-stt-${id}`,id)
    addRules()
    addEmailRules()
})



$('button[data-trigger="add-new-online"]').on('click',function(){
    let id =  parseInt($(this).data().length)+1
    console.log(id)
    $(this).data('length',id)
    $('.pushOnline').append(`
    <hr/>  
     <div data-row="new" class="row justify-content-between">
                                <div class="col-md-5">
                                    <div class="input-wrapper   ">
                                        <label class="input-wrapper__label ">
                                            ${prestashop.language.iso_code==="pl" ? 'Nazwa' :'Name'}:    
                                        </label>
                                        <div class="">
                                            <input class="input-wrapper-xl__input required" data-id="${id}" name="name" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="input-wrapper   ">
                                        <label class="input-wrapper__label ">
                                            ${prestashop.language.iso_code==="pl" ? 'Strona www' :'Website'}:
                                        </label>
                                        <div class="">
                                            <input class="input-wrapper-xl__input required" data-id="${id}" name="website" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="input-wrapper   ">
                                        <label class="input-wrapper__label ">
                                                                    ${prestashop.language.iso_code==="pl" ? 'Strona Facebook(opcjonalnie)' :'Facebook(optional)'}:                                                

                                        </label>
                                        <div class="">
                                            <input class="input-wrapper-xl__input" name="facebook" data-id="${id}" type="text" value="" >
                                        </div>
                                    </div>


                                </div>
                     
                                <div class="col-md-5">
                                    <div class="input-wrapper  ">
                                        <label class="input-wrapper__label ">
                                      ${prestashop.language.iso_code==="pl" ? 'Ulica,nr domu/lokalu' :'Street, address number'}:                                                
                                        </label>
                                        <div class="">
                                            <input class="input-wrapper-xl__input required" data-id="${id}" name="address1" type="text" value="" >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-5">
                                            <div class="input-wrapper   ">
                                                <label class="input-wrapper__label ">
                               ${prestashop.language.iso_code==="pl" ? 'Kod pocztowy' :'Post code'}:                                                

                                                </label>
                                                <div class="">
                                                    <input class="input-wrapper-xl__input required postcode" data-id="${id}" data-iso-code="PL" name="postcode[${id}]"  type="text" value="" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-7">
                                            <div class="input-wrapper   ">
                                                <label class="input-wrapper__label ">
                                                             ${prestashop.language.iso_code==="pl" ? 'Miejsowość' :'City'}: 

                                                </label>
                                                <div class="">
                                                    <input class="input-wrapper-xl__input required" data-id="${id}" name="city" type="text" value="" >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                     <div class="input-wrapper   ">
                                        <label class="input-wrapper__label ">
                                   ${prestashop.language.iso_code==="pl" ? 'Województwo' :'Voivodeship'}: 
                                        </label>
                                        <div class="">
                                            <input class="input-wrapper-xl__input " data-id="${id}"  name="voivodeship" type="text" value="" >
                                        </div>
                                    </div>
                                    
                                    <div class="input-wrapper   ">
                                            <label class="input-wrapper__label ">     
                                   ${prestashop.language.iso_code==="pl" ? 'Kraj' :'Country'}: 
                                            </label>
                                            <div class="select-stationary-${id}">
                                              
                                            </div>
                                    </div>
                                   
                                </div>


                            </div>`)

    select(`.select-stationary-${id}`,id)
    addRules()
    addEmailRules()

})


$('button[data-trigger="add-new-allegro"]').on('click',function(){
    let id =  parseInt($(this).data().length)+1
    console.log(id)
    $(this).data('length',id)
    $('.pushAllegro').append(`
    <hr/>  
<div data-row="new" class="row justify-content-between">
    <div class="col-md-5">
    <div class="input-wrapper   ">
    <label class="input-wrapper__label ">
    ${prestashop.language.iso_code==="pl" ? 'Nazwa' :'Name'}:
</label>
<div class="">
    <input class="input-wrapper-xl__input required" data-id="${id}" required name="name[${id}]" type="text" value="">
    </div>
    </div>
    <div class="input-wrapper   ">
    <label class="input-wrapper__label ">
    ${prestashop.language.iso_code==="pl" ? 'Nick Allegro' :'Allegro nickname'}:
    </label>
<div class="">
    <input class="input-wrapper-xl__input required" data-id="${id}" required name="allegro_nick[${id}]" type="text" value="">
    </div>
    </div>
    <div class="input-wrapper   ">
    <label class="input-wrapper__label ">
    ${prestashop.language.iso_code==="pl" ? 'Strona Facebook(opcjonalnie)' :'Facebook'}:
    
</label>
<div class="">
    <input class="input-wrapper-xl__input" name="facebook[${id}]" data-id="${id}" type="text" value="" >
</div>
</div>
</div>
</div>
`)
addEmailRules()
})


$('button[data-trigger="add-new-other"]').on('click',function(){
    let id =  parseInt($(this).data().length)+1
    console.log(id)
    $(this).data('length',id)
    $('.newOther').append(`
    <hr/>  
       <div class="row justify-content-between">
                       
                            <div class="col-md-5">
                                <div class="input-wrapper   ">
                                    <label class="input-wrapper__label ">
                                        
    ${prestashop.language.iso_code==="pl" ? 'Informacje' :'Information'}:
                                        
                                    </label>
                                    <div class="">
                                        <input class="input-wrapper-xl__input required" data-id="${id}" name="text[${id}]" required type="text" value="" >
                                    </div>
                                </div>
                            </div>
                   

                        </div>`)
})

const select = (el,id)=>{
    const select = $(`<select data-id="${id}" name="id_country" style="width:100%;height:45px"  value="PL" class="setIsoCode select2-custom-white filter-sort js-sorting custom-select-filter white input-wrapper-xl__input "> </select>`)
    countries.forEach((c)=>{
        select.append($(`<option  selected="${c.iso_code==='PL'}" data-iso-code="${c.iso_code}" value="${c.iso_code}">${c.name}</option>`))
    })
    select.val('PL')
    $(el).append(select)
}
const selectPrefix = (el,id)=>{
    const select = $(`<select data-id="${id}" name="customerPhonePrefix[${id}]" style="width:100%;height:45px"  value="+48" class="setPrefix select2-custom-white filter-sort js-sorting custom-select-filter white input-wrapper-xl__input "> </select>`)
    countries.forEach((c)=>{
        select.append($(`<option  selected="${c.iso_code==='PL'}" data-iso-code="${c.iso_code}" data-prefix="${c.call_prefix}"  value="${c.call_prefix}">+${c.call_prefix}</option>`))
    })
    select.val('PL')
    $(el).prepend(select)
}





const addRules = () =>{
    $('[name*="postcode"]').each(function() {
        const $this = $(this)
        // console.log($this)

        $this.rules("add",
            {
                remote:{
                    url:`${prestashop.urls.base_url}index.php?fc=module&module=cg_register&controller=Verify-Postcode&`,
                    type:'get',
                    data:{
                        iso_code:function(){
                            return $this.data().isoCode
                        },
                        value:function(){
                            return $this.val()
                        }
                    },
                    dataFilter: function(data) {
                        var json = JSON.parse(data)
                        return json.valid

                    }
                },
            
                messages:{
                    
                    remote:currentMessages.validPostCode
                }
            })
    })
}




setTimeout(()=>{
    addRules()

},1760)


    const addEmailRules = () => {
        // $(document).ready(() => {
        console.log('create new one')
        jQuery.validator.addMethod("customemail", 
            (value, element) => {
                    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
            })
        
        // $('.inputjqcheck').validate({
        //     // jQuery.validator.addMethod("accept", (value, element, param) => {
        //     //     return value.match(new RegExp("^" + param + "$"))
        //     // })
            
        //     rules: {
        //         email: {
        //             customemail: true,
        //             required: true
        //         }
        //     },
        //     messages: {
        //         email: {
        //             customemail: currentMessages.validEmail,
        //         }
        //     }
        // })
        $('[name*="email"]').each(function() {
            const $this = $(this)
            console.log('co to',$this)
    
            $this.rules("add",
                {
                    email: {
                        customemail: true,
                    },
                    messages:{
                        email: {
                            customemail: currentMessages.validMail
                        }                        
                    }
          
                })
        })

        console.log($this.validate())
        }
     
    
    
    