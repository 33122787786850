import 'select2/dist/css/select2.min.css'
import '../style/theme.scss'
import 'select2/dist/js/select2.full.min'
import 'jquery-validation/dist/jquery.validate.min'
import customQuantity from './quantity'
import customQuantityWithLink from './quantity_with_link'


// import 'owl.carousel'

import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
import DropDownMenu from './dropdown'
import { FiltersManager } from './filters'
import { OrderPanel } from './orderPanel'
import Select from './select'
import mapboxgl from 'mapbox-gl' // or "const mapboxgl = require('mapbox-gl');"
import 'mapbox-gl/dist/mapbox-gl.css'
import './userAction'
import './edit'
import './navbar'
import './backgrounds'
import './register'
import './quantity'
import './register-valid'
import './edit-data-valid'
import './dynamicFields'

const checkWrapperPadding = () => {
  const height = $('#header').height()
  $('#wrapper').css('margin-top', `${height}px`)
}

window.addEventListener('resize', checkWrapperPadding)

function initSwippers(finded) {
  finded.each(function () {
    setTimeout(() => {
      console.log('.arrow-right-post-' + $(this).data('gallery-id'))
      new Swiper('.' + $(this).data('gallery-id'), {
        spaceBetween: 10,
        slidesPerView: 3,
        navigation: {
          nextEl: `.arrow-right-post-${$(this).data('gallery-id')}`,
          prevEl: `.arrow-left-post-${$(this).data('gallery-id')}`,
        },
        breakpoints: {
          // when window width is <= 499px
          0: {
            slidesPerView: 1,
            spaceBetweenSlides: 20,
          },
          // when window width is <= 999px
          769: {
            slidesPerView: 2,
            spaceBetweenSlides: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetweenSlides: 20,
          },
        },
      })
    }, 1500)
  })
}




$(document).ready(() => {
  console.log(prestashop.page.page_name)
  if (
    prestashop.page.page_name === 'module-cg_order_panel-panel' ||
    prestashop.page.page_name === 'module-cg_order_panel-Panel'
  ) {
    console.log('init panel')
    var init = new OrderPanel()

    $('.clear-filters').on('click', () => {
      init.clearFilter()
    })
  }
  if (prestashop.page.page_name === 'cart') {
    customQuantityWithLink()
  }
  initSwippers($('[data-gallery-id]'))
  if (
    prestashop.page.page_name === 'module-cg_contact-Contact' || prestashop.page.page_name ==='module-cg_contact-contact' ||
    prestashop.page.page_name === 'contact'
  ) {
    const coords = $('#coordinate').data()
    mapboxgl.accessToken =
      'pk.eyJ1Ijoic3pvYmlwIiwiYSI6ImNrYmhtNjg2ajA2bjUzMXF2aXc0bGt2Z24ifQ.SW63Kbtp01-vUBn5TGCbng'
    const map = new mapboxgl.Map({
      container: 'mgl-map-wrapper',
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: [coords.lat, coords.lng], // starting position [lng, lat]
      zoom: 14, // starting zoom
    })

    var marker = new mapboxgl.Marker()
      .setLngLat([coords.lat, coords.lng])
      .addTo(map)
  }

  checkWrapperPadding()

  if (prestashop.urls.current_url.includes('n:1')) {
    $('[data-checkbox="new"]').prop('checked', true)
  }

  const filterManager = new FiltersManager()
  const manufactureSelect = new Select($('.js-manufacture-multiple'), {
    placeholder: '',

    containerCssClass: 'select2-custom',
    minimumResultsForSearch: -1,
    closeOnSelect: false,
  })
  manufactureSelect.init()

  const sortingSelect = new Select($('.js-sorting'), {
    placeholder: '',
    containerCssClass: 'select2-custom',
    closeOnSelect: true,
    minimumResultsForSearch: -1,
  })
  sortingSelect.init()

  const orderManufacture = new Select($('.js-order-manufacture'), {
    placeholder: ' - ',
    containerCssClass: 'select2-custom',
    closeOnSelect: true,
    minimumResultsForSearch: -1,
  })
  orderManufacture.init()

  const orderCategory = new Select($('.js-order-category'), {
    placeholder: '-',
    containerCssClass: 'select2-custom',
    closeOnSelect: true,
    minimumResultsForSearch: -1,
  })
  orderCategory.init()
  // const  = new Select($('.change-shop'), {
  //   placeholder: '-',
  //   containerCssClass: 'select2-custom',
  //   closeOnSelect: true,
  //   minimumResultsForSearch: -1,
  // })
  // orderCategory.init()



  const orderSubCategory = new Select($('.js-order-subcategory'), {
    placeholder: '-',
    containerCssClass: 'select2-custom',
    closeOnSelect: true,
    minimumResultsForSearch: -1,
  })
  orderSubCategory.init()

  const Post = new Swiper('.Post', {
    spaceBetween: 10,
    slidesPerView: 3,
    navigation: {
      nextEl: '.arrow-right-post',
      prevEl: '.arrow-left-post',
    },
  })
  new Swiper('.gallery-1', {
    spaceBetween: 10,
    slidesPerView: 3,
    navigation: {
      nextEl: '.arrow-right-post',
      prevEl: '.arrow-left-post',
    },
  })

  const galleryThumbs = new Swiper('.Thumbs', {
    direction: 'vertical',
    spaceBetween: 10,
    slidesPerView: 3,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  })
  const galleryTop = new Swiper('.Gallery', {
    direction: 'vertical',
    spaceBetween: 10,
    thumbs: {
      swiper: galleryThumbs,
    },
    navigation: {
      nextEl: '.swiper-button-next-c',
      prevEl: '.swiper-button-prev-c',
    },
  })
  galleryTop.params.control = galleryThumbs
  galleryThumbs.params.control = galleryTop

})

$('body').on('click', '[data-action="show-password"]', function () {
  const input = $('#' + $(this).data('focus'))
  if (input.attr('type') === 'password') {
    input.attr('type', 'text')
  } else {
    input.attr('type', 'password')
  }
})

$('body').on('click', '[data-action=""]', function () {
  const input = $('#' + $(this).data('focus'))
  if (input.attr('type') === 'password') {
    input.attr('type', 'text')
  } else {
    input.attr('type', 'password')
  }
})


$('body').on('click', '[data-action="quantity-action"]', function () {
      $.ajax({
        type:'GET',
        url:$(this).data().link,
        success(){
          location.reload()
        }
      })
})

